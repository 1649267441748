<template>
  <div id="printMe" class="flex flex-col xl:grid grid-cols-5 gap-5">
    <div class="flex items-center justify-center col-span-5 space-x-2">
      <button @click="previousMonth">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-secondary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
        </svg>
      </button>
      <datepicker
        style="cursor: pointer !important; width: 9rem"
        class="border-none text-2xl text-center date-input bg-secondary text-white rounded-md py-0"
        :placeholder="'Month'"
        :inputFormat="'MM.yyyy'"
        :minimumView="'month'"
        :maximumView="'year'"
        :initialView="'year'"
        :locale="lan"
        v-model="movementTransferDate"
      ></datepicker>
      <button @click="nextMonth">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-secondary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
        </svg>
      </button>
    </div>
    <div class="auflistung-side col-span-2">
      <div v-if="accountOverview.length" class="auflistung-side-inner border-2 border-secondary rounded xs:p-5 overflow-x-auto">
        <table class="table w-345 xs:w-full anfang mx-auto px-2">
          <thead>
            <th class="text-right">{{ $t("Start stand") }}</th>
            <th class="text-right">{{ $t("end result") }}</th>
          </thead>
          <tbody class="w-full" v-for="(account, index) in accountOverview" :key="index">
            <tr class="bg-primary w-full capitalize rounded-t-md">
              <td class="font-semibold" colspan="2">{{ $t(account.name) }}</td>
            </tr>
            <tr>
              <td
                class="border border-emerald-500 text-emerald-600 font-semibold text-right print-text-center-border"
                :class="{
                  'text-green-500': Math.sign(account.totalBefore) == true,
                  'text-gray-900': Math.sign(account.totalBefore) === 0,
                  'text-red-500': Math.sign(account.totalBefore) == -1,
                }"
              >
                {{
                  parseFloat(account.totalBefore).toLocaleString("de-DE", {
                    minimumFractionDigits: 2,
                  })
                }}
              </td>
              <td
                class="border border-emerald-500 text-emerald-600 font-semibold text-right print-text-center-border"
                :class="{
                  'text-green-500': Math.sign(account.totalEnd) == true,
                  'text-gray-900': Math.sign(account.totalEnd) === 0,
                  'text-red-500': Math.sign(account.totalEnd) == -1,
                }"
              >
                {{
                  parseFloat(account.totalEnd).toLocaleString("de-DE", {
                    minimumFractionDigits: 2,
                  })
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="auflistung-main col-span-3">
      <div class="geplante-bewegungen mb-8 border-b-2 border-gray-300 pb-8">
        <div class="content-title listtophover listtopactive flex justify-between self-center text-xl font-light border-2 border-secondary shadow rounded-md py-1 px-5 cursor-pointer">
          <h2>{{ $t("Planned Transfer") }}</h2>
          <span class="show-icon show text-secondary print-display" @click="isPlanningOpen = !isPlanningOpen">
            <ChevronDownIcon class="h-6 w-6" v-if="isPlanningOpen" />
            <ChevronUpIcon class="h-6 w-6" v-else />
          </span>
        </div>
        <collapse-transition dimension="height">
          <section class="gp-bewegungen" v-show="isPlanningOpen">
            <div class="overflow-x-auto">
              <div class="gp-bewegungen-top-row sm:w-full">
                <div class="text-xs justify-between text-primary mb-4 mt-4 rounded border-2 border-secondary px-5 py-2 grid grid-cols-3 gap-2 print-flex print-flex-row">
                  <!-- <div class="gp-bew-cat print-display">
                    <span>{{ $t("select") }}</span>
                  </div> -->
                  <div class="gp-bew-cat print-w-4 print-text-lg">
                    <span>{{ $t("From account") }}</span>
                  </div>
                  <div class="gp-bew-betrag text-center print-w-4 print-text-lg">
                    <span>{{ $t("To account") }}</span>
                  </div>
                  <div class="gp-bew-pers flex justify-end print-w-4 print-text-right print-text-lg">
                    <span>{{ $t("Amount") }}</span>
                    <span class="print-display">/{{ $t("action") }}</span>
                  </div>
                  <!-- <div class="gp-bew-pers text-center">
                    <span>{{ $t("comment") }}</span>
                  </div> -->
                  <!-- <div class="gp-bew-show text-right print-display">
                    <span>{{ $t("action") }}</span>
                  </div> -->
                </div>
              </div>

              <div class="sm:w-full">
                <div>
                  <div v-for="(item, index) in effectiveTransferPlanningListPaginated" :key="index">
                    <div class="items-center grid grid-cols-3 gap-2 bg-gray-100 rounded-md text-gray-700 my-px py-2 px-5 mt-1 print-flex print-mt-1">
                      <div class="flex items-center print-w-4">
                        <div class="flex items-center h-5 col-span-1 print-display">
                          <input id="offers" v-model="selectedTransfer" :value="item.id" aria-describedby="offers-description" name="offers" type="checkbox" class="focus:ring-secondary h-4 w-4 text-secondary border-gray-300 rounded mr-4" />
                        </div>
                        <span @click="openModalMovement(item, 'update')" class="font-semibold text-sm capitalize w-full cursor-pointer">{{ item.source_account ? $t(item.source_account.name) : "-" }}</span>
                      </div>
                      <div @click="openModalMovement(item, 'update')" class="items-center text-center truncate cursor-pointer print-w-4">
                        <span class="font-semibold text-sm text-center capitalize">{{ item.destination_account ? $t(item.destination_account.name) : "-" }}</span>
                      </div>
                      <div class="flex justify-between w-full print-w-4 print-text-right">
                        <div @click="openModalMovement(item, 'update')" class="w-full text-right mr-2 cursor-pointer">
                          <span class="font-semibold text-sm capitalize">
                            {{
                              parseFloat(item.amount).toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                              })
                            }}
                          </span>
                        </div>
                        <div class="move print-display">
                          <button @click="setEffective(item)">
                            <ClipboardCopyIcon class="h-6 w-6 text-blue-400" />
                          </button>
                        </div>
                      </div>
                      <!-- <div class="items-center text-center truncate">
                        <span class="font-semibold text-sm capitalize truncate">
                          {{ item.comment ? item.comment : "-" }}
                        </span>
                      </div> -->
                      <!-- <div class="flex justify-end print-display">
                        <div class="edit">
                          <button>
                            <PencilAltIcon
                              class="h-6 w-6 text-fuchsia-600"
                              @click="openModalMovement(item, 'update')"
                            />
                          </button>
                        </div>
                        <div class="move">
                          <button @click="setEffective(item)">
                            <ClipboardCopyIcon class="h-6 w-6 text-blue-400" />
                          </button>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="main-action p-2 gap-2 text-sm text-secondary rounded mt-5 flex justify-between">
              <div class="flex gap-5 print-display">
                <div class="move">
                  <button
                    :disabled="selectedTransfer.length <= 1"
                    :class="
                      selectedTransfer.length <= 1
                        ? 'border border-secondary bg-secondary text-white py-1 px-2 rounded-lg font-light print-display opacity-40 cursor-not-allowed '
                        : 'border border-secondary bg-secondary text-white py-1 px-2 rounded-lg font-light print-display'
                    "
                    @click="setEffective(selectedTransfer)"
                    title="Bewegung zu effektiven verschieben"
                    class="shadow-lg flex items-center text-lg border border-secondary py-1 px-2 rounded-lg font-light"
                  >
                    <span class="sm:block">{{ $t("Move to effective") }}</span>
                    <ClipboardCopyIcon class="h-6 w-6" />
                  </button>
                </div>
              </div>
            </div>
          </section>
        </collapse-transition>
        <nav class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 print-display" aria-label="Pagination">
          <div class="hidden sm:block">
            <p class="text-sm text-gray-700">
              {{ $t("Showing") }}
              <span class="font-medium">{{ (currentPage - 1) * itemsPerPage + 1 }}</span>
              {{ $t("to") }}
              <span class="font-medium">{{ Math.min(currentPage * itemsPerPage, totalItems) }}</span>
              {{ $t("of") }}
              <span class="font-medium">{{ totalItems }}</span>
              {{ $t("results") }}
            </p>
            <div class="text-sm text-gray-700 ml-3 mt-2">
              {{ $t("show per page") }}
              <select v-model="itemsPerPage" class="rounded-md border-gray-300">
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="1000">{{ $t("All") }}</option>
              </select>
            </div>
          </div>
          <div class="flex-1 flex justify-between sm:justify-end">
            <button
              @click="firstPage"
              :disabled="currentPage === 1"
              :class="
                currentPage === 1
                  ? 'border border-secondary text-sm font-medium rounded-md text-white bg-secondary cursor-not-allowed opacity-40	'
                  : 'border border-secondary text-sm font-medium rounded-md text-white hover:text-secondary hover:bg-white bg-secondary'
              "
              class="mr-1 relative inline-flex items-center px-1 py-2"
            >
              <ChevronDoubleLeftIcon class="h-4 w-4" />
            </button>
            <button
              @click="previousPage"
              :disabled="currentPage === 1"
              :class="
                currentPage === 1
                  ? 'border border-secondary text-sm font-medium rounded-md text-white bg-secondary cursor-not-allowed opacity-40'
                  : 'border border-secondary text-sm font-medium rounded-md text-white hover:text-secondary hover:bg-white bg-secondary'
              "
              class="relative inline-flex items-center px-4 py-2"
            >
              {{ $t("Previous") }}
            </button>
            <button
              @click="nextPage"
              :disabled="currentPage === totalPages"
              :class="
                currentPage === totalPages
                  ? 'border border-secondary text-sm font-medium rounded-md text-white bg-secondary cursor-not-allowed opacity-40	'
                  : 'border border-secondary text-sm font-medium rounded-md text-white hover:text-secondary hover:bg-white bg-secondary'
              "
              class="ml-3 relative inline-flex items-center px-4 py-2"
            >
              {{ $t("Next") }}
            </button>
            <button
              @click="lastPage"
              :disabled="currentPage === totalPages"
              :class="
                currentPage === totalPages
                  ? 'border border-secondary text-sm font-medium rounded-md text-white bg-secondary cursor-not-allowed opacity-40	'
                  : 'border border-secondary text-sm font-medium rounded-md text-white hover:text-secondary hover:bg-white bg-secondary'
              "
              class="ml-1 relative inline-flex items-center px-1 py-2"
            >
              <ChevronDoubleRightIcon class="h-4 w-4" />
            </button>
          </div>
        </nav>
      </div>

      <div class="mb-12 border-b-2 border-gray-300 pb-8">
        <div class="add print-display">
          <button
            type="button"
            @click="openModal({}, 'new')"
            title="neue Bewegungen erstellen"
            class="shadow-lg flex items-center text-lg bg-secondary text-white border-2 py-1 px-2 rounded-lg hover:text-secondary hover:bg-white hover:border-secondary"
          >
            <span class="sm:block">{{ $t("New transfer") }}</span>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
            </svg>
          </button>
        </div>
        <div class="mt-8 content-title listtophover flex justify-between self-center text-xl font-light border-2 border-secondary shadow rounded-md py-1 px-5 cursor-pointer">
          <h2>{{ $t("Effective Transfer") }}</h2>
          <span class="show-icon hide text-secondary print-display" @click="isEffectiveOpen = !isEffectiveOpen">
            <ChevronDownIcon class="h-6 w-6" v-if="isEffectiveOpen" />
            <ChevronUpIcon class="h-6 w-6" v-else />
          </span>
        </div>
        <collapse-transition dimension="height">
          <section class="" v-show="isEffectiveOpen">
            <div class="overflow-x-auto">
              <div class="gp-bewegungen-top-row w-440 sm:w-full">
                <div class="text-xs justify-between text-primary mb-4 mt-4 rounded border-2 border-secondary px-5 py-2 grid grid-cols-4 gap-2 print-flex print-flex-row">
                  <div class="gp-bew-cat print-w-3 print-text-lg">
                    <span>{{ $t("Date") }}</span>
                  </div>
                  <div class="gp-bew-cat print-w-3 print-text-lg">
                    <span>{{ $t("From account") }}</span>
                  </div>
                  <div class="gp-bew-betrag text-center print-w-3 print-text-lg">
                    <span>{{ $t("To account") }}</span>
                  </div>
                  <div class="gp-bew-pers text-right print-w-3 print-text-right print-text-lg">
                    <span>{{ $t("Amount") }}</span>
                  </div>
                  <!-- <div class="gp-bew-pers text-center">
                    <span>{{ $t("comment") }}</span>
                  </div>
                  <div class="gp-bew-show text-right print-display">
                    <span>{{ $t("action") }}</span>
                  </div> -->
                </div>
              </div>
              <div class="w-440 sm:w-full">
                <div>
                  <div v-for="(item, index) in effectiveTransferList.data" :key="index">
                    <div @click="openModal(item, 'update')" class="items-center grid grid-cols-4 gap-2 bg-gray-100 rounded-md text-gray-700 my-px py-2 px-5 cursor-pointer mt-1 print-flex print-mt-1">
                      <div class="items-center print-w-3">
                        <span class="font-semibold text-sm capitalize">{{ convertDate(item.date) }}</span>
                      </div>

                      <div class="items-center print-w-3">
                        <span class="font-semibold text-sm capitalize">{{ item.source_account ? $t(item.source_account.name) : "-" }}</span>
                      </div>
                      <div class="items-center text-center print-w-3">
                        <span class="font-semibold text-sm text-center capitalize">{{ item.destination_account ? $t(item.destination_account.name) : "-" }}</span>
                      </div>
                      <div class="items-center text-right print-w-3 print-text-right">
                        <span class="font-semibold text-sm capitalize">
                          {{
                            parseFloat(item.amount).toLocaleString("de-DE", {
                              minimumFractionDigits: 2,
                            })
                          }}
                        </span>
                      </div>
                      <!-- <div class="items-center text-center truncate">
                        <span class="font-semibold text-sm capitalize truncate">
                          {{ item.comment ? item.comment : "-" }}
                        </span>
                      </div> -->
                      <!-- <div class="flex justify-end print-display">
                        <div class="edit">
                          <button>
                            <PencilAltIcon
                              class="h-6 w-6 text-fuchsia-600"
                              @click="openModal(item, 'update')"
                            />
                          </button>
                        </div>
                        <div class="delete">
                          <button @click="deleteMovement(item.id)">
                            <TrashIcon class="h-6 w-6 text-red-400" />
                          </button>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <nav class="bg-white px-4 py-3 flex flex-col sm:flex-row items-center justify-between border-t border-gray-200 sm:px-6 print-display" aria-label="Pagination">
              <div v-if="effectiveTransferList.meta" class="flex flex-col items-center">
                <p class="text-sm text-gray-700">
                  {{ $t("Showing") }}
                  <span class="font-medium">{{ effectiveTransferList.meta.from || 0 }}</span>
                  {{ $t("to") }}
                  <span class="font-medium">{{ effectiveTransferList.meta.to || 0 }}</span>
                  {{ $t("of") }}
                  <span class="font-medium">{{ effectiveTransferList.meta.total || 0 }}</span>
                  {{ $t("results") }}
                </p>
                <div class="text-sm text-gray-700 ml-3 mt-2">
                  {{ $t("show per page") }}
                  <select @change="formatCountPagination" v-model="count" class="rounded-md border-gray-300">
                    <option :selected="count == 10" value="10">10</option>
                    <option :selected="count == 20" value="20">20</option>
                    <option :selected="count == 30" value="30">30</option>
                    <option :selected="count == 1000" value="1000">{{ $t("All") }}</option>
                  </select>
                </div>
              </div>

              <div v-if="effectiveTransferList.meta" class="flex-1 flex justify-between sm:justify-end mt-2 sm:mt-0">
                <button
                  @click="firstPageMovementList"
                  :class="
                    effectiveTransferList.meta.current_page == 1
                      ? 'border border-secondary text-sm font-medium rounded-md text-white bg-secondary cursor-not-allowed opacity-40	'
                      : 'border border-secondary text-sm font-medium rounded-md text-white hover:text-secondary hover:bg-white bg-secondary'
                  "
                  class="mr-1 relative inline-flex items-center px-1 py-2"
                >
                  <ChevronDoubleLeftIcon class="h-4 w-4" />
                </button>
                <button
                  @click="prePageMovementList"
                  :disabled="effectiveTransferList.meta.current_page == 1"
                  :class="
                    effectiveTransferList.meta.current_page == 1
                      ? 'border border-secondary text-sm font-medium rounded-md text-white bg-secondary cursor-not-allowed opacity-40'
                      : 'border border-secondary text-sm font-medium rounded-md text-white hover:text-secondary hover:bg-white bg-secondary'
                  "
                  class="relative inline-flex items-center px-4 py-2"
                >
                  {{ $t("Previous") }}
                </button>
                <button
                  @click="nextPageMovementList"
                  :disabled="effectiveTransferList.meta.current_page == effectiveTransferList.meta.last_page"
                  :class="
                    effectiveTransferList.meta.current_page == effectiveTransferList.meta.last_page
                      ? 'border border-secondary text-sm font-medium rounded-md text-white bg-secondary cursor-not-allowed opacity-40	'
                      : 'border border-secondary text-sm font-medium rounded-md text-white hover:text-secondary hover:bg-white bg-secondary'
                  "
                  class="ml-3 relative inline-flex items-center px-4 py-2"
                >
                  {{ $t("Next") }}
                </button>
                <button
                  @click="nextLastPageMovementList"
                  :class="
                    effectiveTransferList.meta.current_page == effectiveTransferList.meta.last_page
                      ? 'border border-secondary text-sm font-medium rounded-md text-white bg-secondary cursor-not-allowed opacity-40	'
                      : 'border border-secondary text-sm font-medium rounded-md text-white hover:text-secondary hover:bg-white bg-secondary'
                  "
                  class="ml-1 relative inline-flex items-center px-1 py-2"
                >
                  <ChevronDoubleRightIcon class="h-4 w-4" />
                </button>
              </div>
            </nav>
            <div class="main-action p-2 gap-2 text-sm text-secondary rounded mt-5 flex justify-between">
              <div class="add print-display">
                <button type="button" @click="exportCsv" title="export" class="shadow-lg flex items-center text-lg border-2 border-secondary bg-secondary text-white py-1 px-2 rounded-lg hover:text-secondary hover:bg-white hover:border-secondary">
                  <span class="sm:block">{{ $t("export") }}</span>
                  <DocumentDownloadIcon class="h-6 w-6" />
                </button>
              </div>
            </div>
          </section>
        </collapse-transition>
      </div>
    </div>
  </div>
  <modal
    :open="showModal"
    @close="
      () => {
        showModal = false;
        getEffectiveTransferPlanningList();
        getEffectiveTransferList();
        getAccountsInfo();
        this.selectedTransfer.length = 0;
      }
    "
    @fetchData="refreshData"
    :type="type"
    :data="transfer"
    :mode="mode"
  ></modal>
</template>

<script>
const monthList = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
import Modal from "@/components/Modal";
import Datepicker from "../../node_modules/vue3-datepicker";
import { de, it } from "date-fns/locale";

import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";

import { ClipboardCopyIcon, ChevronDownIcon, ChevronUpIcon, DocumentDownloadIcon, ChevronDoubleRightIcon, ChevronDoubleLeftIcon } from "@heroicons/vue/outline";

export default {
  components: {
    Modal,
    ClipboardCopyIcon,
    CollapseTransition,
    ChevronDownIcon,
    ChevronUpIcon,
    DocumentDownloadIcon,
    ChevronDoubleRightIcon,
    ChevronDoubleLeftIcon,
    Datepicker,
  },
  data() {
    return {
      showModal: false,
      type: null,
      transfer: null,
      mode: null,
      search: null,
      monthList,
      selectedTransfer: [],
      isEffectiveOpen: true,
      isPlanningOpen: true,
      advancedSearch: false,
      movementTransferDate: new Date(),
      lan: this.$i18n.locale == "de" ? de : it,
      count: 10,
      page: 1,
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
    };
  },
  computed: {
    transferList() {
      return this.$store.state.transfer.transferList;
    },
    movementData() {
      return this.$store.state.movement.movementData;
    },
    effectiveTransferPlanningList() {
      return this.$store.state.transfer.effectiveTransferPlanningList || [];
    },
    effectiveTransferList() {
      return this.$store.state.transfer.effectiveTransferList;
    },
    accountOverview() {
      return this.$store.state.accounts.accountOverview;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    effectiveTransferPlanningListPaginated() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      return this.effectiveTransferPlanningList.slice(startIndex, startIndex + this.itemsPerPage);
    },
  },
  watch: {
    movementTransferDate: function () {
      this.refreshData();
      this.$store.commit("setMainDate", this.movementTransferDate);
    },
    itemsPerPage: function () {
      if (this.currentPage > this.totalPages) {
        this.currentPage = this.totalPages;
      }
    },
    effectiveTransferPlanningList(newList) {
      this.totalItems = newList.length;
    },
  },
  methods: {
    nextPage() {
      if (this.currentPage < this.totalPages) this.currentPage++;
    },
    previousPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    firstPage() {
      this.currentPage = 1;
    },
    lastPage() {
      this.currentPage = this.totalPages;
    },
    nextPageMovementList() {
      this.page = this.effectiveTransferList.meta.current_page + 1;
      this.getEffectiveTransferList();
    },
    nextLastPageMovementList() {
      this.page = this.effectiveTransferList.meta.last_page;
      this.getEffectiveTransferList();
    },
    prePageMovementList() {
      this.page = this.effectiveTransferList.meta.current_page - 1;
      this.getEffectiveTransferList();
    },
    firstPageMovementList() {
      this.page = 1;
      this.getEffectiveTransferList();
    },
    exportCsv() {
      this.$store.dispatch("transfer/exportCv", {
        start: this.startDate(),
        end: this.endDate(),
        language: localStorage.getItem("haushaltsLang") ? localStorage.getItem("haushaltsLang") : "de",
      });
    },
    openModal(transfer, mode) {
      this.$store.dispatch("accounts/getAccountList", { count: 1000 }).then(() => {
        this.showModal = true;
        this.transfer = transfer;
        this.mode = mode;
        this.type = "MovementTransferModal";
      });
    },
    openModalMovement(transfer, mode) {
      this.$store.dispatch("accounts/getAccountList").then(() => {
        this.showModal = true;
        this.transfer = transfer;
        this.mode = mode;
        this.type = "transferModal";
      });
    },

    deleteMovement(id) {
      this.$store.dispatch("transfer/deleteEffectiveTransfer", id).then(() => {
        this.refreshData();
      });
    },
    setEffective(data) {
      this.showModal = true;
      this.transfer = data;
      this.type = this.selectedTransfer.length > 1 ? "effectiveModal" : "SingleEffectiveModal";
      this.mode = "transferEffective";
    },
    deletePlan(data) {
      typeof data == Object ? Object.values(data).join(",") : data;
      this.$store.dispatch("planning/DeletePlan", data);
    },

    endMonthDay() {
      const endDate = new Date(this.movementTransferDate.getFullYear(), this.movementTransferDate.getMonth() + 1, 0);
      return endDate.getDate();
    },
    getAccountsInfo() {
      this.$store.dispatch("accounts/getAccountOverview", {
        start: this.startDate(),
        end: this.endDate(),
      });
    },

    getEffectiveTransferPlanningList() {
      this.$store.dispatch("transfer/getEffectiveTransferPlanningList", {
        from: this.startDate(),
        to: this.endDate(),
      });
    },
    getEffectiveTransferList() {
      this.$store.dispatch("transfer/getEffectiveTransferList", {
        start: this.startDate(),
        end: this.endDate(),
        count: this.count,
        page: this.page,
      });
    },
    convertDate(date) {
      let year = new Date(date).getFullYear();
      let month = (new Date(date).getMonth() + 1).toString().padStart(2, "0");
      let day = new Date(date).getDate().toString().padStart(2, "0");
      return `${day}-${month}-${year}`;
    },
    refreshData() {
      this.getEffectiveTransferPlanningList();
      this.getAccountsInfo();
      this.getEffectiveTransferList();
    },
    startDate() {
      return `${this.movementTransferDate.getFullYear()}-${(this.movementTransferDate.getMonth() + 1).toString().padStart(2, "0")}-01`;
    },
    endDate() {
      return `${this.movementTransferDate.getFullYear()}-${(this.movementTransferDate.getMonth() + 1).toString().padStart(2, "0")}-${this.endMonthDay()}`;
    },
    nextMonth() {
      if (this.movementTransferDate.getMonth() == 11) {
        this.movementTransferDate = new Date(this.movementTransferDate.getFullYear() + 1, 0, 1);
      } else {
        this.movementTransferDate = new Date(this.movementTransferDate.getFullYear(), this.movementTransferDate.getMonth() + 1, 1);
      }
    },
    previousMonth() {
      if (this.movementTransferDate.getMonth() == 0) {
        this.movementTransferDate = new Date(this.movementTransferDate.getFullYear() - 1, 11, 1);
      } else {
        this.movementTransferDate = new Date(this.movementTransferDate.getFullYear(), this.movementTransferDate.getMonth() - 1, 1);
      }
    },
    formatCountPagination() {
      this.refreshData();
    },
  },
  mounted() {
    this.refreshData();
    this.$store.commit("setMainDate", new Date());
  },
};
</script>

<style></style>
